import './App.css';

function App() {
  return (
    <div className="App">
      <div class="container">
        <div class="column column1">
          <img src="logo.png" alt="garmzy.com logo" />
          <h2>NEW WEBSITE COMING SOON</h2>
          <p>Contact: john.asafo@garmzy.com for details</p>
        </div>
        <div class="column column2"></div>
      </div>
    </div>
  );
}

export default App;
